import React from 'react'
import {ScenarioMetadata, VariationsStoreReader} from 'types/types'

import {Modules} from '../../types/Modules'
import {DebugPanelPageHeader} from '../DebugPanelPageHeader'
import {SecretCode} from '../ProtectionScreen/ProtectionScreen'
import DebugPanel from './DebugPanel'
import {PageWrapper} from './styled'
type PropsTypes = {
  protectCode?: SecretCode
  thirdPartyExperiments?: string[]
  scenarios?: ScenarioMetadata[]
  variationsStoreReader?: VariationsStoreReader
  modulesConfig: Modules[]
}

export const DebugPanelPage = ({
  protectCode = [],
  thirdPartyExperiments = [],
  scenarios = [],
  variationsStoreReader,
  modulesConfig
}: PropsTypes) => {
  return (
    <PageWrapper>
      <DebugPanelPageHeader />
      <DebugPanel
        protectCode={protectCode}
        thirdPartyExperiments={thirdPartyExperiments}
        variationsStoreReader={variationsStoreReader}
        scenarios={scenarios}
        modulesConfig={modulesConfig}
      />
    </PageWrapper>
  )
}

// default export needed due to lazy loading this component
export default DebugPanelPage
