export const getExperimentsFromUrl = url => {
  const searchParamsEntries = new URLSearchParams(url)
  const experiments = {}

  for (const entry of searchParamsEntries.entries()) {
    if (entry[0].startsWith('ab_')) {
      experiments[entry[0].slice(3)] = entry[1] === 'a' ? 'a' : 'b'
    }
  }

  return {
    experiments,
    persist: Boolean(searchParamsEntries.get('persistExperiments'))
  }
}

export const copyToClipboard = async (str: string) => {
  if (navigator?.clipboard?.writeText) return navigator.clipboard.writeText(str)
  return Promise.reject(new Error('The Clipboard API is not available.'))
}

export const copyToClipboardAndNotify = str => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  copyToClipboard(str).then(() => {
    // eslint-disable-next-line no-alert
    alert(`Successfully copied ${str}`)
  })
}

export const stringifyToggles = (toggles, prefix = '&') => {
  return `${prefix}${(Object.entries(toggles) as string[][])
    .filter(([, value]) => ['a', 'b'].includes(value))
    .map(([key, value]) => `ab_${key}=${value}`)
    .join('&')}&persistExperiments=1`
}
