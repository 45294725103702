export enum Pages {
  HP = 'HP',
  SRP = 'SRP',
  RSP = 'RSP',
  AP = 'AP',
  PP = 'PP',
  CP = 'CP',
  BM = 'BM',
  MB = 'MB',
  NPS = 'NPS'
}

export enum EndpointNames {
  Rooms = 'Rooms',
  Hotel = 'Hotel',
  OfferCheck = 'OfferCheck',
  BookingCreation = 'BookingCreation',
  BookingCreationAsync = 'BookingCreationAsync',
  BookingFinalisation = 'BookingFinalisation',
  Raa = 'Raa',
  AlgoliaLov = 'AlgoliaLov',
  AlgoliaHotel = 'AlgoliaHotel',
  BookingStatus = 'BookingStatus',
  Offers = 'Offers',
  SapiAnchor = 'SapiAnchor',
  AlternativeAvailability = 'AlternativeAvailability',
  Search = 'Search',
  Bookings = 'Bookings',
  DealFreezeOffers = 'DealFreezeOffers',
  DealFreezeBookings = 'DealFreezeBookings',
  DealFreezeUserLimits = 'DealFreezeUserLimits',
  User = 'User',
  SbOfferCheck = 'SbOfferCheck',
  BookingRetrieve = 'BookingRetrieve'
}

export enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put'
}
