import React, {useEffect, useState} from 'react'
import {DebugPanelProps} from 'types/types'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {SegmentedControl} from '@daedalus/atlas/SegmentedControl'
import {parseQueryString} from '@daedalus/core/src/utils/url'

import {ExperimentTogglePage} from '../../debugModules/ExperimentToggle'
import {MagicSortPage} from '../../debugModules/MagicSort'
import {ScenarioSimulatorPage} from '../../debugModules/ScenarioSimulator'
import {SettingsPage} from '../../debugModules/Settings'
import {Modules} from '../../types/Modules'
import {getPageSelectorNameFromModule} from '../../utils'
import {ProtectionScreen} from '../ProtectionScreen/ProtectionScreen'
import {Content, HeaderSelector} from './styled'

export const DebugPanel = ({
  protectCode = [],
  thirdPartyExperiments = [],
  variationsStoreReader,
  scenarios = [],
  modulesConfig,
  updateLocation
}: DebugPanelProps) => {
  const [showDebug, setShowDebug] = useState(
    Boolean(localStorage['FH.debugAvailable']) || protectCode.length === 0
  )
  const [page, setPage] = useState<Modules>(modulesConfig[0])
  const {isMobile} = useDeviceLayout()
  const storeExperiments = variationsStoreReader
    ? variationsStoreReader.getAll()
    : []
  const enableDebug = () => {
    localStorage['FH.debugAvailable'] = true
    setShowDebug(true)
  }

  useEffect(() => {
    const {accessCode} = parseQueryString(window.location.search)
    if (!accessCode) return
    const matches = (accessCode as string)
      .split(',')
      .every(
        (value, index) => protectCode[index] === Number.parseInt(value, 10)
      )
    if (matches) {
      enableDebug()
      window.history.replaceState({}, document.title, window.location.pathname) // Strip the param from the URL
    }
  }, [])

  const pageValueToPageModulesMap = {
    [Modules.EXPERIMENTS]: (
      <ExperimentTogglePage
        storeExperiments={storeExperiments}
        thirdPartyExperiments={thirdPartyExperiments}
      />
    ),
    [Modules.SIMULATOR]: <ScenarioSimulatorPage scenarios={scenarios} />,
    [Modules.SETTINGS]: <SettingsPage />,
    [Modules.SORT]: (
      <MagicSortPage updateLocation={updateLocation} isMobile={isMobile} />
    )
  }

  return (
    <>
      {showDebug ? (
        <Content>
          <HeaderSelector
            name="pageSwitcher"
            value={page}
            onChange={value => setPage(value as Modules)}
          >
            {modulesConfig.map(module => (
              <SegmentedControl.Item value={module} key={module}>
                {getPageSelectorNameFromModule(module)}
              </SegmentedControl.Item>
            ))}
          </HeaderSelector>
          {pageValueToPageModulesMap[page]}
        </Content>
      ) : (
        <ProtectionScreen protectCode={protectCode} onSuccess={enableDebug} />
      )}
    </>
  )
}

// default export needed due to lazy loading this component
export default DebugPanel
