import React, {useState} from 'react'

import {Button} from '@daedalus/atlas/Button'
import {Checkbox} from '@daedalus/atlas/Checkbox'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid/Grid'
import {InputText} from '@daedalus/atlas/InputText'
import {Text} from '@daedalus/atlas/Text'
import {
  getAnonymousId,
  persistAnonymousId
} from '@daedalus/core/src/_web/anonymousId'
import {generateAnonymousId} from '@daedalus/core/src/anonymousId'

import {getIsoptimizelyDisabled} from '../../modules/localstorage/getIsOptimizelydisabled'
import {PageWrapper} from './styled'
const FORCE_DISABLE_OPTIMIZELEY_LOG_KEY = 'force-disable-optimizely-logs'

export const SettingsPage = () => {
  const [disableOptimizelyLogs, setDisableOptimizelyLogs] = useState(
    getIsoptimizelyDisabled()
  )
  const [componentAnonymousId, setComponentAnonymousId] = useState('')
  const handleDisableOptimizely = e => {
    localStorage.setItem(
      FORCE_DISABLE_OPTIMIZELEY_LOG_KEY,
      `${e.target.checked}`
    )
    setDisableOptimizelyLogs(v => !v)
  }
  const placeholder = getAnonymousId() || 'AnonymousId'
  return (
    <PageWrapper>
      <ContentWrapper paddingBottom="s300">
        <Text variant="titleL">Settings</Text>
      </ContentWrapper>
      <ContentWrapper paddingTop="s500" paddingBottom="s300">
        <Checkbox
          onChange={handleDisableOptimizely}
          checked={disableOptimizelyLogs}
          name={'force-disable-optimizely-logs'}
          label={'Disable Optimizely logs'}
        />
      </ContentWrapper>
      <ContentWrapper paddingBottom="s300">
        <Text variant="titleM">AnonymousId options</Text>
        <InputText
          id="anonymousIdInput"
          placeholder={placeholder}
          size="sm"
          name="anonymousIdInput"
          onChange={text => setComponentAnonymousId(text)}
          value={componentAnonymousId}
        />
      </ContentWrapper>
      <Grid container gap="s300">
        <Button
          size="sm"
          onClick={() => persistAnonymousId(componentAnonymousId)}
        >
          <Text>Set new anonymousId</Text>
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            const newAnonymousId = generateAnonymousId()
            persistAnonymousId(newAnonymousId)
            setComponentAnonymousId(newAnonymousId)
          }}
        >
          <Text>Generate and set new anonymousId (UUID)</Text>
        </Button>
      </Grid>
    </PageWrapper>
  )
}
