import React from 'react'

import {Icon} from '@daedalus/atlas/Icon'

import {ExperimentToggle} from '../../debugModules/ExperimentToggle/utils'
import {
  ActionIconWrapper,
  ExperimentId,
  ExperimentWrapper,
  RowWrapper,
  StatusBadge
} from '../ExperimentList/styled'

type PropsTypes = {
  toggle: ExperimentToggle
  onChange: (id: string, order: string) => void
  onRemove: (id: string) => void
}

const values = ['auto', 'a', 'b']
export const ToggleItem = ({toggle, onChange, onRemove}: PropsTypes) => {
  const change = () => {
    onChange(
      toggle.id,
      values[(values.indexOf(toggle.forcedTo) + 1) % values.length]
    )
  }

  const removeExperiment = () => {
    onRemove(toggle.id)
  }

  return (
    <RowWrapper>
      <ExperimentWrapper variant={toggle.forcedTo ? 'forced' : 'received'}>
        <ExperimentId onClick={change}>{toggle.id}</ExperimentId>
        {toggle.forcedTo ? (
          <>
            <StatusBadge status={toggle.forcedTo} onClick={change}>
              {toggle.forcedTo}
            </StatusBadge>
            <ActionIconWrapper onClick={removeExperiment}>
              <Icon name="Close" />
            </ActionIconWrapper>
          </>
        ) : (
          <StatusBadge status={'auto'}>{toggle.storeValue}</StatusBadge>
        )}
      </ExperimentWrapper>
    </RowWrapper>
  )
}
