import React from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import SapiMagicSortOverlay from '@daedalus/shared/src/search/SapiMagicSortOverlay'

import {PageWrapper} from './styled'

interface Props {
  updateLocation?: (params: Record<string, unknown>) => void
  isMobile: boolean
}

export const MagicSortPage = ({updateLocation, isMobile}: Props) => {
  return (
    <PageWrapper isMobile={isMobile}>
      {isMobile ? (
        <>
          <Icon name="Iphone" size="lg" />
          <div> Mobile is not supported yet</div>
        </>
      ) : (
        <SapiMagicSortOverlay updateLocation={updateLocation} />
      )}
    </PageWrapper>
  )
}
