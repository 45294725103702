import React, {useCallback, useEffect, useRef, useState} from 'react'
import {RoutePropsTypes} from 'types/types'

import {useToastController} from '@daedalus/atlas/context/toastController'
import {Icon} from '@daedalus/atlas/Icon'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'

import DebugPanelOverlay from '../components/DebugPanel/DebugPanelOverlay'
import {APP_ENV_PRODUCTION_LIST} from '../constants'
import {MetaContextProvider} from '../context/meta'
import {getForcedToggles} from '../modules/localstorage/localstorageToggles'
import {getExperimentsFromUrl} from '../modules/utils'
import {
  getPersistedSimulationFlag,
  getUrlSimulationFlag
} from '../utils/scenarioSimulator'

type Props = RoutePropsTypes & {
  isMobile: boolean
  children: React.ReactNode
  isSyntheticTest?: boolean
  appEnv: string
  updateLocation?: (params: Record<string, unknown>) => void
}

export const OverlayProvider = ({
  children,
  protectCode,
  thirdPartyExperiments,
  variationsStoreReader,
  scenarios,
  modulesConfig,
  meta,
  isMobile,
  appEnv,
  isSyntheticTest,
  updateLocation
}: Props) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [consecutiveTouches, setConsecutiveTouches] = useState(0)

  const persistedSimulationFlag = getPersistedSimulationFlag()
  const urlSimulationFlag = getUrlSimulationFlag()

  const allSimulationFlags = [
    ...new Set([persistedSimulationFlag, urlSimulationFlag])
  ]
    .filter(Boolean)
    .map((flag: string) => flag.split('|') as string[])
    .flat()

  const persistedForcedExperiments = getForcedToggles()
  const urlForcedExperiments = getExperimentsFromUrl(window.location.search)

  const allForcedExperiments = {
    ...persistedForcedExperiments,
    ...urlForcedExperiments.experiments
  }

  const simulationToast = useToastController()
  const experimentToast = useToastController()

  const touchTimeout = useRef<NodeJS.Timeout | null>(null)

  const containerRef = React.useRef<HTMLDivElement>(null)

  // debug panel in prod is enabled when enableDebugPanel=1 is passed in url param
  const enableDebugPanelInProd = getQueryStringObject()?.['enableDebugPanel']
  const isDebugPanelAllowedInProd =
    APP_ENV_PRODUCTION_LIST.includes(appEnv) && enableDebugPanelInProd === '1'

  const isDebugPanelAllowed =
    !APP_ENV_PRODUCTION_LIST.includes(appEnv) || isDebugPanelAllowedInProd

  const handleTouchStart = useCallback(e => {
    if (e.touches.length === 3) {
      if (touchTimeout.current) clearTimeout(touchTimeout.current)
      setConsecutiveTouches(prev => prev + 1)
      touchTimeout.current = setTimeout(() => {
        setConsecutiveTouches(0)
      }, 1500)
    }
  }, [])

  const handleCloseByCancel = () => {
    setIsPanelOpen(false)
  }

  const onClose = () => {
    if (isPanelOpen) {
      setIsPanelOpen(false)
    }
  }

  useEffect(() => {
    const down = e => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        if (isDebugPanelAllowed) {
          e.preventDefault()
          setIsPanelOpen(isOpen => !isOpen)
        }
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [isDebugPanelAllowed, isPanelOpen])

  useEffect(() => {
    if (consecutiveTouches === 3) {
      if (isDebugPanelAllowed) {
        setIsPanelOpen(true)
        setConsecutiveTouches(0)
        if (touchTimeout.current) clearTimeout(touchTimeout.current)
      }
    }
  }, [consecutiveTouches, isDebugPanelAllowed])

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener('touchstart', handleTouchStart, {
        passive: false
      })
      containerRef.current.addEventListener(
        'touchcancel',
        handleCloseByCancel,
        {
          passive: false
        }
      )
    }
    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener('touchstart', handleTouchStart)
        containerRef.current.removeEventListener(
          'touchcancel',
          handleCloseByCancel
        )
      }
    }
  }, [handleTouchStart, containerRef])

  useEffect(() => {
    if (isPanelOpen || isSyntheticTest) {
      simulationToast.close()
      experimentToast.close()
    }
  }, [experimentToast, isPanelOpen, simulationToast, isSyntheticTest])

  useEffect(() => {
    if (
      allSimulationFlags.length &&
      !isPanelOpen &&
      !isSyntheticTest &&
      isDebugPanelAllowed
    ) {
      simulationToast.close()
      simulationToast.open(
        <ul>
          {[...allSimulationFlags].map((simulationFlag: string) => {
            return <li key={simulationFlag}>{simulationFlag}</li>
          })}
        </ul>,
        {
          title: 'Simulating:',
          showCloseButton: true,
          autoClose: false,
          icon: <Icon name="Settings" />
        }
      )
    }
  }, [allSimulationFlags.length, isPanelOpen, isDebugPanelAllowed])

  useEffect(() => {
    if (
      Object.keys(allForcedExperiments).length &&
      !isPanelOpen &&
      !isSyntheticTest &&
      isDebugPanelAllowed
    ) {
      experimentToast.close()
      experimentToast.open(
        <ul>
          {Object.keys(allForcedExperiments).map(experiment => {
            return (
              <li key={experiment}>
                {experiment}: {allForcedExperiments[experiment]}
              </li>
            )
          })}
        </ul>,
        {
          title: 'Forcing experiments:',
          showCloseButton: true,
          autoClose: false,
          icon: <Icon name="SettingsSliders" />
        }
      )
    }
  }, [allForcedExperiments.length, isPanelOpen])

  return (
    <div ref={containerRef}>
      <MetaContextProvider value={meta}>
        <DebugPanelOverlay
          protectCode={protectCode}
          thirdPartyExperiments={thirdPartyExperiments}
          scenarios={scenarios}
          modulesConfig={modulesConfig}
          isOpen={isPanelOpen}
          variationsStoreReader={variationsStoreReader}
          onClose={onClose}
          isMobile={isMobile}
          updateLocation={updateLocation}
        />
        {children}
      </MetaContextProvider>
    </div>
  )
}
