import {Modules} from '../types/Modules'

export const getPageSelectorNameFromModule = (module: Modules) => {
  switch (module) {
    case Modules.EXPERIMENTS:
      return 'Experiments'
    case Modules.SIMULATOR:
      return 'Simulator'
    case Modules.SETTINGS:
      return 'Settings'
    case Modules.SORT:
      return 'Sort'
    default:
  }
}
