import React, {useEffect, useState} from 'react'
import {ScenarioMetadata} from 'types/types'

import {Accordion} from '@daedalus/atlas/Accordion'
import {Button} from '@daedalus/atlas/Button'
import {Grid} from '@daedalus/atlas/helpers/Grid/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import {Text} from '@daedalus/atlas/Text'
import {Tooltip} from '@daedalus/atlas/Tooltip'

import {NoScenarios} from '../../components/NoScenarios/NoScenarios'
import {
  buildScenarioFlag,
  getPersistedSimulationFlag,
  parseScenarioFlag,
  persistSimulation,
  removeSimulation
} from '../../utils/scenarioSimulator'
import {
  CustomRadio,
  DescriptionWrapper,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  RadioGroup,
  RouteInfoContainer
} from './styles'

interface Props {
  scenarios: ScenarioMetadata[]
}

const PIPE_SIMULATION_INFO =
  "Piping simulations won't function if both scenarios share the same page, method, and endpoint in their configuration. This limitation arises because we can only register one handler per combination. If the handler differs in method, endpoint, or page, it will work. But if these parameters are identical, only the first scenario will work. To pipe two scenarios with the same configuration, you'll need to merge them into a single scenario that accommodates both data transformations alongside the existing ones."
const SIMULATION_PARAM_INFO =
  'Use this URL param to share a link with a simulation enabled'

export const ScenarioSimulatorPage = ({scenarios}: Props) => {
  const [selectedScenario, setSelectedScenario] = useState<ScenarioMetadata>(
    scenarios[0]
  )
  const [selectedVariation, setSelectedVariation] = useState(
    selectedScenario?.variations[0]
  )

  useEffect(() => {
    const persistedSimulation = getPersistedSimulationFlag()
    if (!persistedSimulation) return

    const {scenarioValue, variation} = parseScenarioFlag(persistedSimulation)
    const foundScenario = scenarios.find(
      scenario => scenario.value === scenarioValue
    )

    if (foundScenario) {
      setSelectedScenario(foundScenario)
      const foundVariation = foundScenario?.variations.find(
        variant => variant.value === variation
      )
      if (foundVariation) setSelectedVariation(foundVariation)
    }
  }, [scenarios])

  const handleOptionChange = value => {
    const newScenario = scenarios?.find(scenario => scenario.value === value)
    setSelectedScenario(newScenario)
    setSelectedVariation(newScenario?.variations[0] || null)
  }

  const handleSetSimulationClick = () => {
    const scenarioFlag = buildScenarioFlag(
      selectedScenario.value,
      selectedVariation?.value
    )
    persistSimulation(scenarioFlag)
  }

  const handlePipeSimulation = () => {
    const scenarioFlag = buildScenarioFlag(
      selectedScenario.value,
      selectedVariation?.value
    )
    const existingSetSimulationFlag = getPersistedSimulationFlag()
    const pipedSimulationFlag = `${existingSetSimulationFlag}|${scenarioFlag}`
    if (!existingSetSimulationFlag) return persistSimulation(scenarioFlag)
    persistSimulation(pipedSimulationFlag)
  }

  if (!scenarios.length) return <NoScenarios />
  return (
    <PageWrapper padding="s000">
      <PageTitle variant="titleL">Scenario</PageTitle>
      <InputSelect onChange={handleOptionChange}>
        {scenarios?.map(scenario => (
          <option
            value={scenario.value}
            key={scenario.value}
            selected={selectedScenario.value === scenario.value}
          >
            {scenario.name}
          </option>
        ))}
      </InputSelect>
      {selectedScenario?.variations.length > 0 && (
        <>
          <PageSubtitle variant="titleM">Scenario variations</PageSubtitle>
          <RadioGroup>
            {selectedScenario.variations.map((variation, index) => (
              <CustomRadio
                value={variation.value}
                name={variation.displayName}
                key={`${selectedScenario.value}-${variation.value}-${index}`}
                hasTouchArea
                fullWidth
                label={variation.displayName}
                checked={selectedVariation.value === variation.value}
                onChange={() => setSelectedVariation(variation)}
                labelSize="bodyM"
              />
            ))}
          </RadioGroup>
        </>
      )}
      <DescriptionWrapper>
        <Tooltip content={SIMULATION_PARAM_INFO}>
          <Icon name="Information" size="sm" />
        </Tooltip>
        <Text variant="titleM">{`Simulation param: &simulate=${buildScenarioFlag(
          selectedScenario.value,
          selectedVariation?.value
        )}`}</Text>
        <Text variant="titleM">Scenario description: </Text>
        <Text variant="bodyM">{selectedScenario?.description}</Text>
        {selectedVariation && (
          <>
            <Text variant="titleM">Variation description: </Text>
            <Text variant="bodyM">{selectedVariation?.description}</Text>
          </>
        )}
      </DescriptionWrapper>
      {selectedScenario && selectedVariation && (
        <Grid container gap="s200">
          <Grid mobileSm="fit">
            <Button size="sm" onClick={handleSetSimulationClick}>
              Set simulation
            </Button>
          </Grid>
          <Grid mobileSm="fit" container alignItems="center">
            <Button
              size="sm"
              variant="secondary"
              onClick={handlePipeSimulation}
            >
              Pipe new simulation
            </Button>
            <Tooltip content={PIPE_SIMULATION_INFO}>
              <Icon name="Information" size="sm" />
            </Tooltip>
          </Grid>
          <Grid mobileSm="fit">
            <Button size="sm" variant="danger" onClick={removeSimulation}>
              Clear simulation
            </Button>
          </Grid>
        </Grid>
      )}
      <Accordion header="More information" size="sm">
        <RouteInfoContainer container direction="column">
          {selectedScenario?.routes.map((route, index) => (
            <>
              <Text variant="titleM">{`Route #${index + 1}`}</Text>
              <Grid alignItems="center">
                <Text variant="bodyM">Endpoint to match: </Text>
                <Text variant="bodyS">{route.endpoint}</Text>
              </Grid>
              <Grid alignItems="center">
                <Text variant="bodyM">Method to match: </Text>
                <Text variant="bodyS">{route.method}</Text>
              </Grid>
              <Grid alignItems="center">
                <Text variant="bodyM">Page to match: </Text>
                <Text variant="bodyS">{route.page}</Text>
              </Grid>
              {selectedScenario?.scope && (
                <Grid alignItems="center">
                  <Text variant="bodyM">Scope of the scenario: </Text>
                  <Text variant="bodyS">{selectedScenario?.scope}</Text>
                </Grid>
              )}
              {((selectedScenario && selectedVariation) ||
                selectedScenario) && (
                <Grid alignItems="center">
                  <Text variant="bodyM">Simulation flag: </Text>
                  <Text variant="bodyS">
                    {buildScenarioFlag(
                      selectedScenario.value,
                      selectedVariation?.value
                    )}
                  </Text>
                </Grid>
              )}
              <Grid wrap="wrap" alignItems="left" direction="column">
                <Text variant="bodyM">
                  Variations that will work for this config:
                </Text>
                <ul>
                  {route?.variations &&
                    route.variations.map(variation => (
                      <li key={variation.value}>
                        <Text variant="bodyS">{variation.displayName}</Text>
                      </li>
                    ))}
                </ul>
              </Grid>
            </>
          ))}
        </RouteInfoContainer>
      </Accordion>
    </PageWrapper>
  )
}
