import React, {useEffect} from 'react'

import {
  deleteToggle,
  getForcedToggles,
  saveToggles
} from '../modules/localstorage'

export const useLocalstorageToggles = () => {
  const [currentState, setCurrentState] = React.useState({})

  useEffect(() => {
    setCurrentState(getForcedToggles())
  }, [])

  const set = (name: string, value: string) => {
    const newToggles = {
      ...currentState,
      [name]: value
    }

    setCurrentState(saveToggles(newToggles))
  }

  const remove = name => {
    setCurrentState(deleteToggle(name))
  }

  return {
    toggles: currentState,
    remove,
    set
  }
}
