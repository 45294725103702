import React, {ChangeEvent} from 'react'
import {VariationsStoreReader} from 'types/types'

import {Button} from '@daedalus/atlas/Button'
import {useToastController} from '@daedalus/atlas/context/toastController'
import {Stack} from '@daedalus/atlas/helpers/Stack'
import {InputText} from '@daedalus/atlas/InputText'
import {Text} from '@daedalus/atlas/Text'

import {ToggleItem} from '../../components/DebugPanel/ToggleItem'
import {
  InputRowWrapper,
  InputWrapper
} from '../../components/ExperimentList/styled'
import {useMetaContext} from '../../context/meta'
import {useLocalstorageToggles} from '../../hooks/useLocalstorageToggles'
import {copyToClipboardAndNotify, stringifyToggles} from '../../modules/utils'
import {CopySectionWrapper, Form} from './styled'
import {composeToggles} from './utils'

type PropsTypes = {
  thirdPartyExperiments: string[]
  storeExperiments: ReturnType<VariationsStoreReader['getAll']>
}

export const ExperimentTogglePage = ({
  thirdPartyExperiments,
  storeExperiments
}: PropsTypes) => {
  const formRef = React.useRef()
  const meta = useMetaContext()
  const {toggles, set, remove} = useLocalstorageToggles()

  const togglesList = Object.keys(toggles)

  const toast = useToastController()

  const onAddToggle = e => {
    const toggle = e.target.elements['experiment.id'].value
    submitExperimentToggle(toggle)

    e.target.reset()
    e.preventDefault()
  }

  const onToggleThirdPartyFlag = (
    flag: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (thirdPartyExperiments.includes(flag)) {
      submitExperimentToggle(flag)

      e.currentTarget.value = ''
      e.currentTarget.blur()
    }
  }

  const submitExperimentToggle = (toggle: string) => {
    set(toggle, 'b')
    toast.open(`Experiment [${toggle}] forced now to B side`)
  }

  const composedExperiments = composeToggles(storeExperiments, toggles)

  const onSetToggle = (name, value) => {
    set(name, value)
  }

  const activeExperiments = composedExperiments.filter(
    toggle => !toggle.forcedTo
  )

  const activeOverrides = composedExperiments.filter(toggle => toggle.forcedTo)

  return (
    <div>
      <Stack space="s600">
        <CopySectionWrapper>
          <span>Copy:</span>
          <Button
            size="md"
            disabled={togglesList.length === 0}
            onClick={() =>
              copyToClipboardAndNotify(stringifyToggles(toggles, '&'))
            }
          >
            URL params
          </Button>
          <Button
            size="md"
            disabled={togglesList.length === 0}
            onClick={() =>
              copyToClipboardAndNotify(
                `${location.origin}${stringifyToggles(toggles, '?')}`
              )
            }
          >
            Host + URL Params
          </Button>
        </CopySectionWrapper>
        <Stack space="s500">
          <div>
            <div>
              <Text variant="titleL">Active experiments:</Text>
            </div>
            {activeExperiments.map(toggle => (
              <ToggleItem
                key={toggle.id}
                toggle={toggle}
                onChange={onSetToggle}
                onRemove={remove}
              />
            ))}
          </div>

          <div>
            <div>
              <Text variant="titleL">Active overrides:</Text>
            </div>
            {activeOverrides.length > 0
              ? activeOverrides.map(toggle => (
                  <ToggleItem
                    key={toggle.id}
                    toggle={toggle}
                    onChange={onSetToggle}
                    onRemove={remove}
                  />
                ))
              : 'No active overrides. Add experiment ID manually or import it from Optimizely'}
          </div>

          <div>
            <Form ref={formRef} onSubmit={onAddToggle}>
              <InputRowWrapper>
                <InputWrapper>
                  <InputText
                    id="experiment.id"
                    placeholder="Add experimentId manually"
                    size="lg"
                    list="experiments-list-data"
                    name="experiment.id"
                    onChange={onToggleThirdPartyFlag}
                  />
                  <datalist id="experiments-list-data">
                    {thirdPartyExperiments.map(exp => {
                      return <option key={exp} value={exp} />
                    })}
                  </datalist>
                </InputWrapper>
                <Button size="lg" variant="secondary" type="submit">
                  Add
                </Button>
              </InputRowWrapper>
            </Form>
          </div>
        </Stack>

        <section>
          <Text variant="titleL">Anonymous id:</Text>
          <Text>{meta?.anonymousId}</Text>
        </section>
      </Stack>
    </div>
  )
}
