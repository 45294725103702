import React from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'

import {TitleBar} from './styled'

export const NoScenarios = () => {
  return (
    <>
      <TitleBar>
        <Icon
          size="xl"
          name="WarningTriangle"
          colorPath="alert.warning.background"
        />
        <Text variant="titleXL">No scenarios found</Text>
      </TitleBar>
      <Text variant="bodyS">
        Make sure that you are passing them as a prop in the debug panel route,
        follow the documentation for more information.
      </Text>
    </>
  )
}
