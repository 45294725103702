export type ExperimentToggle = {
  id: string
  storeValue: 'b' | 'a'
  forcedTo: 'a' | 'b'
}

export const composeToggles = (
  activeState = {},
  forcedState = {}
): ExperimentToggle[] => {
  const joinedKeys = Object.keys(activeState).concat(Object.keys(forcedState))
  const keys = [...new Set(joinedKeys)] //remove duplicates

  return keys.map(key => ({
    id: key,
    storeValue: activeState[key] || undefined,
    forcedTo: forcedState[key]
  }))
}
