import React from 'react'

import {Button} from '@daedalus/atlas/Button'

import {BackButtonWrapper} from './styled'

export const DebugPanelPageHeader = () => {
  return (
    <BackButtonWrapper>
      <Button
        variant="secondary"
        onClick={() => {
          location.href = '/'
        }}
      >
        Back to Vio
      </Button>
    </BackButtonWrapper>
  )
}
