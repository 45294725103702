import React, {useEffect, useState} from 'react'

import useWindowSize from '@daedalus/core/src/_web/utils/browser/hooks/useWindowSize'

// 0 - click left side of the screen, 1 - click on right side of the screen
export type SecretCode = Array<0 | 1>

type PropsTypes = {
  protectCode: SecretCode
  onSuccess: () => void
}

export const ProtectionScreen = ({protectCode, onSuccess}: PropsTypes) => {
  const [stage, setStage] = useState(0)
  const size = useWindowSize()

  const onClick = e => {
    const xPos = e.clientX >= size.width / 2 ? 1 : 0

    if (protectCode[stage] === xPos) {
      const newStage = stage + 1
      setStage(newStage)
      if (newStage >= protectCode.length) {
        onSuccess()
      }
    } else {
      setStage(0)
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClick)
    return () => {
      window.removeEventListener('click', onClick)
    }
  })

  return <div>404 - not found, error code: {protectCode.join('')}</div>
}
